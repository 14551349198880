import { LinkProps } from 'antd/lib/typography/Link';

import {
  FontWightEnum,
  LinkSizeEnum
} from 'common/globalTypes/enums/typography';

export enum LinkTypes {
  small = 'small',
  base = 'base'
}

export interface ILinkProps extends Omit<LinkProps, 'href'> {
  href?: string;
  text?: string;
  className?: string;
  ellipsis?: boolean;
  disabledCurrentLocale?: boolean;
  onClick?: () => void;
  size?: keyof typeof LinkTypes;
  fontWeight?: keyof typeof FontWightEnum;
}

export interface ITypographyLinkStyleParams {
  size: SizeType;
  fontWeight: FontWeightType;
}

export type SizeType = keyof typeof LinkSizeEnum;
export type FontWeightType = 'regular' | 'medium' | 'bold';
