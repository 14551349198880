import notification from 'antd/lib/notification';

type AntdMessageType = 'success' | 'error' | 'warning' | 'info';

const message = (
  content: string,
  type: AntdMessageType = 'info',
  duration = 3
): void => {
  notification[type]({ message: content, duration, placement: 'bottomRight' });
};

export default message;
