'use client';
import { FC, useEffect } from 'react';
import {
  usePathname,
  useRouter,
  useSearchParams,
  useSelectedLayoutSegment,
  useSelectedLayoutSegments
} from 'next/navigation';
import FloatButton from 'antd/lib/float-button';

import MainHeader from 'components/organisms/MainHeader';
import MainFooter from 'components/organisms/MainFooter';
import useAuthUser from 'hooks/common/useAuthUser';
import { MainStyleWrapper, MainLayoutStyleWrapper } from './styles';
import { IMainLayoutProps } from './types';
import redirectHandlerWithSso from 'utils/helpers/redirectHandlerWithSso';
import createQueryParam from 'utils/helpers/createQueryParam';
import { queryParamRedirectedFromDashboard } from 'common/constants/common';
import useRudderAnalytics from 'hooks/common/useRudderAnalytics';

const MainLayoutTemplate: FC<IMainLayoutProps> = ({ children }) => {
  const { user, isLoggedChecking, logout, logIn } = useAuthUser();
  const segment = useSelectedLayoutSegment();
  const segments = useSelectedLayoutSegments();
  const isItCheckoutPage = segments.includes('checkout');
  const isItProjectsPage = segments.includes('projects');
  const isMainPage = ['c', null].includes(segment);
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const { analyticsPage } = useRudderAnalytics(true);

  useEffect(() => {
    if (searchParams.get(queryParamRedirectedFromDashboard)) {
      const deletedProjectIdQueryParam = createQueryParam(
        {
          projectId: null
        },
        'delete'
      );

      router.replace(deletedProjectIdQueryParam, { scroll: false });
    }
  }, [router, searchParams]);

  useEffect(() => {
    analyticsPage();
  }, [pathname, analyticsPage]);

  const logoutHandler = async () => {
    await logout();
  };

  const navigateToDashboardOrAccountsHandler = (
    projectsTarget: 'app-store' | 'dashboard' | 'blank' | 'accounts',
    returnUrl: string
  ) => {
    redirectHandlerWithSso(projectsTarget, returnUrl);
  };

  return (
    <MainLayoutStyleWrapper>
      <MainHeader
        isMainPage={isMainPage}
        isItCheckoutPage={isItCheckoutPage}
        isLoggedUser={isLoggedChecking}
        user={user}
        onLogout={logoutHandler}
        onLogIn={logIn}
        navigateToDashboardOrAccounts={navigateToDashboardOrAccountsHandler}
      />
      <MainStyleWrapper
        data-main={isMainPage}
        data-checkout-page={isItCheckoutPage}
        data-is-show-project-switcher={isLoggedChecking && !isItProjectsPage}
      >
        {children} <FloatButton.BackTop />
      </MainStyleWrapper>

      <MainFooter />
    </MainLayoutStyleWrapper>
  );
};

export default MainLayoutTemplate;
