'use client';

import { FC, useEffect, useReducer } from 'react';

import { authReducer } from './context/reducer';
import { AuthContext } from './context';
import { IAuthProviderProps } from './types';
import useRudderAnalytics from 'hooks/common/useRudderAnalytics';
import { USER_IDENTIFIED, USER_VERIFIED } from 'common/constants/common';
import isServerSide from 'utils/helpers/isServerSide';

const AuthProvider: FC<IAuthProviderProps> = ({
  children,
  user,
  projectId
}) => {
  const [state, dispatch] = useReducer(authReducer, { user, projectId });
  const { analyticsIdentify } = useRudderAnalytics();

  useEffect(() => {
    if (isServerSide) {
      const userIdentified = localStorage.getItem(USER_IDENTIFIED);
      const { user } = state;

      if (user && user.id && userIdentified !== USER_VERIFIED) {
        analyticsIdentify(user);
        localStorage.setItem(USER_IDENTIFIED, USER_VERIFIED);
      }
    }
  }, [analyticsIdentify, state]);

  const contextValue = { state, dispatch };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
