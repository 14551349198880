import styled from 'styled-components';

import { media } from 'styles/variables.module';

export const AppStoreLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AppStoreLogoDesktopWrapper = styled.div`
  display: block;
  ${media.md(`display: none`)}
`;

export const AppStoreLogoMobileWrapper = styled.div`
  display: none;
  ${media.md(`display: block`)}
`;
