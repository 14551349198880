import styled from 'styled-components';

import { colors, HEIGHT, sizes, zIndex, media } from 'styles/variables.module';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${zIndex.mainHeader};
  width: 100%;
  top: 0;
`;

export const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  height: ${HEIGHT.WEB.HEADER};
  position: fixed;
  z-index: ${zIndex.mainHeader};
  top: 0;
  width: 100%;
  background-color: ${colors.gray_1};
  border-bottom: 1px solid ${colors.gray_4};

  ${media.sm(`
    height: ${HEIGHT.MOBILE.HEADER};
  `)}
`;

export const LogoStyleWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  padding: ${sizes.spXl} ${sizes.spm};
  ${media.sm(`
    padding: 0 ${sizes.spm};
  `)}

  &[data-single-page='true'] {
    justify-content: center;
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${sizes.sp2xs};
  padding: 0 ${sizes.spm};
`;

export const RightNavigationStyleWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
  justify-content: end;
  padding: 0 ${sizes.spm};
  .mobileLoginAndSearchIcons {
    display: none;
  }
  ${media.sm(`
    align-items: center;
    padding: 0 ${sizes.spm};
    
    .LoginOrSignUp-holder {
      button {
        display: none;
      }
    }
    
    .mobileLoginAndSearchIcons {
      display: block;
    }
  `)}
`;
