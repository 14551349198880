import useAuthContext from 'hooks/common/useAuthContext';
import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import useLogoutMutation from 'hooks/apis/mutations/useLogoutMutation';
import { LOG_OUT_USER } from 'providers/authProvider/context/actionTypes';
import { notValidAuthorizationTokenCallbackApi } from 'common/constants/authorization';
import redirectHandlerWithSso from 'utils/helpers/redirectHandlerWithSso';
import { USER_IDENTIFIED } from 'common/constants/common';
import { deleteProjectId } from 'app/actions';

const useAuthUser = () => {
  const {
    state: { user, projectId },
    dispatch
  } = useAuthContext();

  const { pathname } = useRouteWithLanguage();
  const { logoutUser } = useLogoutMutation();

  const isLoggedChecking = Boolean(user?.id);

  const logout = async () => {
    await deleteProjectId();
    logoutUser();
    dispatch({
      type: LOG_OUT_USER,
      payload: { user: null, projectId: null }
    });
    localStorage.removeItem(USER_IDENTIFIED);
    window.open(notValidAuthorizationTokenCallbackApi, '_top');
  };

  const logIn = async (
    customPath?: string,
    callBackRedirectHandler?: () => void
  ) => {
    if (!isLoggedChecking) {
      const comeBackTo = customPath || pathname;

      redirectHandlerWithSso('app-store', comeBackTo);

      return null;
    }

    return callBackRedirectHandler ? callBackRedirectHandler() : false;
  };

  return {
    user,
    projectId,
    logIn,
    logout,
    isLoggedChecking
  };
};

export default useAuthUser;
