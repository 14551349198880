import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';

import message from 'utils/helpers/message';

const ReactQueryClientConfigInstance = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: 1,
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error: any) => {
        if (error?.message && !error?.hideErrorMessage) {
          message(error?.message, 'error');
        }
      }
    }),
    mutationCache: new MutationCache({
      onError: (error: any) => {
        if (error?.message && !error?.hideErrorMessage) {
          message(error?.message, 'error');
        }
      }
    })
  });

export default ReactQueryClientConfigInstance;
