'use client';

import { FC, PropsWithChildren } from 'react';
import ConfigProvider from 'antd/lib/config-provider';
import theme from 'antd/lib/theme';
import AntdRegistry from '@ant-design/nextjs-registry/lib/AntdRegistry';

import {
  antDesignComponentsProviderConfigs,
  localInterFont
} from './constants';

const AntdConfigRegistryProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: localInterFont.style.fontFamily
        },
        algorithm: theme.defaultAlgorithm,
        components: antDesignComponentsProviderConfigs
      }}
    >
      <AntdRegistry>{children}</AntdRegistry>
    </ConfigProvider>
  );
};

export default AntdConfigRegistryProvider;
