import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/ucraft/ucraft-next/node_modules/next/font/local/target.css?{\"path\":\"src/providers/antdConfigRegistryProvider/constants.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/Inter/Inter-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Inter/Inter-Medium.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Inter/Inter-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"}],\"preload\":true,\"adjustFontFallback\":\"Arial\",\"display\":\"swap\"}],\"variableName\":\"localInterFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/templates/MainLayout/Layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/antdConfigRegistryProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/authProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/reactQueryConfigProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/styledComponentsRegistryProvider/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/ucraft/ucraft-next/src/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/utils/helpers/getLanguageByUrl.ts");
