import styled from 'styled-components';

import { colors, sizes, media } from 'styles/variables.module';

export const AboutContainer = styled.section`
  min-height: ${sizes.sp17xl};
  border-top: 1px solid ${colors.gray_4};
  border-bottom: 1px solid ${colors.gray_4};
  display: flex;
  margin: 0 ${sizes.spm};
  align-items: center;
  ${media.lg(`
    flex-direction: column;
    margin: 0 ${sizes.spm};
    align-items: start;
  `)}
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${sizes.sp3xl} 0;
  > img {
    margin-right: ${sizes.sps};
  }
  > div {
    max-width: 55%;
    padding: ${sizes.spm} ${sizes.sp3xl} 0 0;
    ${media.lg(`
     max-width: 100%;
     padding: ${sizes.spm} 0 0 0;
  `)}
  }
`;

export const RightSideContainer = styled.div`
  flex: 1;
  display: contents;
  ${media.lg(`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${sizes.sp2xl};
  `)}
`;

export const FooterSocialButtons = styled.div`
  display: flex;
  gap: ${sizes.sp3xl};

  ${media.sm(`
    flex-wrap: wrap;
  `)}
`;
