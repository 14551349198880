import BaseApiHandler from 'infrastructure/apiHandlers/baseApiHandler';
import { EndPointEnum, IProjectsRequestModel } from './types';
import {
  IProjectsItemsModel,
  IProjectsResponseModel
} from 'common/globalTypes/interfaces/apiResponseModel';

class ProjectApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  async getProjects(
    queryParam: IProjectsRequestModel
  ): Promise<IProjectsResponseModel> {
    const { applicationId, ...pageAndFirst } = queryParam;
    const applicationIdQueryParam = applicationId && { applicationId };

    return await this.request.accountsFetcher.get<IProjectsResponseModel>({
      url: EndPointEnum.getProjectsPrefix,
      cache: 'no-cache',
      responseModel: 'WithNoDataProperty',
      queryParam: { ...pageAndFirst, ...applicationIdQueryParam }
    });
  }

  async getProjectById(id: number): Promise<IProjectsItemsModel> {
    return await this.request.accountsFetcher.get<IProjectsItemsModel>({
      url: `${EndPointEnum.getProjectsPrefix}/${id}`,
      cache: 'no-cache',
      responseModel: 'WithNoDataProperty',
      hideErrorMessage: true
    });
  }
}

const projectApiHandler = new ProjectApiHandler();
export default projectApiHandler;
