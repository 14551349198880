import { useQuery } from '@tanstack/react-query';
import { notFound } from 'next/navigation';

import { projectByIdKey } from './constants';
import projectApiHandler from 'infrastructure/apiHandlers/project';
import { defaultCachingTime } from 'common/constants/common';
import useAuthUser from 'hooks/common/useAuthUser';
import { projectInfoInitialState } from 'components/templates/Projects/Page/constants';

const useProjectByIdQuery = (id: number, checkError = true) => {
  const { isLoggedChecking } = useAuthUser();
  const enabled = isLoggedChecking && Boolean(id);
  const { data: projectById, ...projectsRestProps } = useQuery({
    queryKey: [projectByIdKey, id],
    queryFn: () => projectApiHandler.getProjectById(id),
    staleTime: defaultCachingTime,
    enabled
  });

  if (projectsRestProps.error && checkError) {
    notFound();
  }

  return {
    projectById: projectById || projectInfoInitialState,
    ...projectsRestProps
  };
};

export default useProjectByIdQuery;
