import { FC } from 'react';

import FooterAboutSection from 'components/molecules/FooterAboutSection';
import FooterPrivacies from 'components/molecules/FooterPrivacies';
import { FooterContainer } from './styles';
import { mainFooterId } from 'common/constants/common';

const MainFooter: FC = () => {
  return (
    <FooterContainer id={mainFooterId}>
      <FooterAboutSection />
      <FooterPrivacies />
    </FooterContainer>
  );
};

export default MainFooter;
MainFooter.displayName = 'Footer';
