import { PlusOutlined } from '@ant-design/icons';
import { usePathname } from 'next/navigation';

import {
  EmptyProjectsTitleAndDescWrapper,
  EmptyProjectsWrapper
} from './styles';
import BodyText from 'components/atoms/BodyText';
import { colors } from 'styles/variables.module';
import Button from 'components/atoms/Button';
import redirectHandlerWithSso from 'utils/helpers/redirectHandlerWithSso';
import useTranslations from 'hooks/common/useTranslations';

const EmptyProjects = () => {
  const { t } = useTranslations('common');
  const pathName = usePathname();

  const createProjectHandler = () => {
    redirectHandlerWithSso('onboarding-start', pathName);
  };

  return (
    <EmptyProjectsWrapper>
      <EmptyProjectsTitleAndDescWrapper>
        <div>
          <BodyText
            text={t('noProjects')}
            color={colors.gray_9}
            size="base"
            fontWeight="medium"
          />
        </div>
        <div>
          <BodyText
            text={t('noProjectsDescription')}
            color={colors.gray_8}
            size="small"
            fontWeight="regular"
          />
        </div>
      </EmptyProjectsTitleAndDescWrapper>
      <div>
        <Button
          style={{ width: '100%' }}
          text={t('createProject')}
          type="primary"
          size="small"
          onClick={createProjectHandler}
          icon={<PlusOutlined />}
        />
      </div>
    </EmptyProjectsWrapper>
  );
};

export default EmptyProjects;
