import { IIcon } from './types';

export const socialMediaIcons: IIcon[] = [
  {
    icon: 'facebookFilled',
    redirectionLink: 'https://www.facebook.com/ucraftcom/'
  },
  {
    icon: 'twitterSquareFilled',
    redirectionLink: 'https://twitter.com/ucraftcom'
  },
  {
    icon: 'instagramFilled',
    redirectionLink: 'https://www.instagram.com/ucraftcom/'
  },
  {
    icon: 'youtubeFilled',
    redirectionLink: 'https://www.youtube.com/ucraftcom'
  },
  {
    icon: 'pinterestIcon',
    redirectionLink: 'https://www.pinterest.com/ucraftcom/'
  },
  {
    icon: 'linkedinFilled',
    redirectionLink: 'https://www.linkedin.com/company/ucraft/'
  },
  {
    icon: 'vimeoIcon',
    redirectionLink: 'https://vimeo.com/ucraft'
  }
];
