import styled from 'styled-components';

import { colors, sizes } from 'styles/variables.module';

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${sizes.spXl};
  background: ${colors.gray_1};
  z-index: 11;
`;
