import Select from 'antd/lib/select';
import styled from 'styled-components';

import { colors, sizes } from 'styles/variables.module';

const { Option } = Select;

export const SelectStyleWrapper = styled(Select)`
  &&& {
    width: 100%;

    .ant-select-selector {
      background: ${colors.gray_3};
      border-radius: ${sizes.sp2xm};
      border: 0;
    }
  }
`;

export const SelectOptionStyleWrapper = styled(Option)`
  &&& {
    background: ${colors.gray_3};
  }
`;
