import { FC } from 'react';

import BodyText from 'components/atoms/BodyText';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { socialMediaIcons } from './constants';
import {
  AboutContainer,
  FooterSocialButtons,
  LeftSideContainer,
  RightSideContainer
} from './styles';
import { IIcon } from './types';
import useTranslations from 'hooks/common/useTranslations';
import { colors } from 'styles/variables.module';

const FooterAboutSection: FC = () => {
  const { t } = useTranslations('footer');

  const handleIconClick = (redirectionLink: string) => {
    window.open(redirectionLink, '_blank', 'noreferrer');
  };

  return (
    <AboutContainer>
      <LeftSideContainer>
        <Icon name="ucLogo" width={38} height={18} />
        <div>
          <BodyText
            text={t('aboutUC')}
            size="small"
            fontWeight="regular"
            color={`${colors.gray_8}`}
          />
        </div>
      </LeftSideContainer>
      <RightSideContainer>
        <FooterSocialButtons>
          {socialMediaIcons.map((item: IIcon, index: number) => (
            <Button
              shape="round"
              type="default"
              key={index}
              onClick={() => handleIconClick(item.redirectionLink)}
              icon={<Icon name={item.icon} width={18} height={18} />}
            />
          ))}
        </FooterSocialButtons>
      </RightSideContainer>
    </AboutContainer>
  );
};

export default FooterAboutSection;
FooterAboutSection.displayName = 'Footer About';
