import Typography from 'antd/lib/typography';
import styled from 'styled-components';
import Link from 'next/link';

import { typography, colors } from 'styles/variables.module';
import { ITypographyLinkStyleParams } from './types';

const { Text } = Typography;

export const TypographyLinkStyle = styled(Text)<ITypographyLinkStyleParams>`
  color: ${colors.blue_6};
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${colors.blue_4};
  }

  ${({ size, fontWeight }) => {
    return `font-size: ${typography.link.size[size]};
    font-weight: ${typography.fontWeight[fontWeight]};
    line-height: ${typography.link.lineHeight[size]};
    letter-spacing: ${typography.link.letterSpacing[size]}};`;
  }}
`;

export const RouteLinkStyle = styled(Link)<ITypographyLinkStyleParams>`
  color: ${colors.blue_6};
  transition: color 0.3s;

  &:hover {
    color: ${colors.blue_4};
  }

  text-decoration: none;

  ${({ size, fontWeight }) => {
    return `font-size: ${typography.link.size[size]};
    font-weight: ${typography.fontWeight[fontWeight]};
    line-height: ${typography.link.lineHeight[size]};
    letter-spacing: ${typography.link.letterSpacing[size]}};`;
  }}
`;
