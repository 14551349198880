'use client';

import { FC } from 'react';
import AntAvatar from 'antd/lib/avatar';

import firstLetterString from 'utils/helpers/firstLetterString';
import { IUserAvatarProps } from './types';

const UserAvatar: FC<IUserAvatarProps> = ({ userName, ...props }) => {
  const firstLetterUserName = firstLetterString(userName);

  return <AntAvatar {...props}>{firstLetterUserName}</AntAvatar>;
};

export default UserAvatar;
