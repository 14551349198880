'use client';

import { FC } from 'react';

import { ILinkProps, LinkTypes } from './types';
import { FontWightEnum } from 'common/globalTypes/enums/typography';
import { TypographyLinkStyle, RouteLinkStyle } from './styles';

const Link: FC<ILinkProps> = ({
  href,
  size = LinkTypes.small,
  className = '',
  fontWeight = FontWightEnum.regular,
  text,
  onClick,
  // disabledCurrentLocale = false,
  children,
  ...restProps
}) => {
  if (href) {
    return (
      <RouteLinkStyle
        href={href}
        size={size}
        className={className}
        fontWeight={fontWeight}
        {...restProps}
      >
        {text || children}
      </RouteLinkStyle>
    );
  }

  return (
    <TypographyLinkStyle
      fontWeight={fontWeight}
      size={size}
      className={className}
      onClick={onClick}
      {...restProps}
    >
      {text || children}
    </TypographyLinkStyle>
  );
};

export default Link;
