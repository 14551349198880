import { FC } from 'react';

import BodyText from 'components/atoms/BodyText';
import NextImage from 'components/atoms/NextImage';
import {
  ImagesContainer,
  PrivaciesContainer,
  ParagraphContainer,
  SocialMediaContainer
} from './styles';
import { images } from './constants';
import useTranslations from 'hooks/common/useTranslations';
import { colors } from 'styles/variables.module';
import { Urls } from './types';
import Link from 'components/atoms/Link';

const FooterPrivacies: FC = () => {
  const { t } = useTranslations('footer');

  return (
    <PrivaciesContainer>
      <ImagesContainer>
        {images.map((image, index) => (
          <NextImage
            key={`${index}+${image}`}
            src={`/assets/images/png/${image}.png`}
            width={40}
            height={26}
            alt="payment_method"
          />
        ))}
      </ImagesContainer>
      <ParagraphContainer>
        <BodyText
          text={t('copyRight')}
          size={'extraSmall'}
          color={colors.gray_9}
        />
        <SocialMediaContainer>
          <Link
            href={Urls.privacy_policy}
            text={t('privacyPolicy')}
            size="small"
            fontWeight="medium"
          />
          <Link
            href={Urls.terms_conditions}
            text={t('termsConditions')}
            size="small"
            fontWeight="medium"
          />
          <Link
            href={Urls.request_an_app}
            text={t('RequestAnApp')}
            size="small"
            fontWeight="medium"
          />
        </SocialMediaContainer>
      </ParagraphContainer>
    </PrivaciesContainer>
  );
};

export default FooterPrivacies;
FooterPrivacies.displayName = 'Footer Privacies';
