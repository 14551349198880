const createQueryParam = (
  queryParams: Record<any, any>,
  action: 'create' | 'delete'
) => {
  const url = new URL(window.location.href);

  for (const [name, value] of Object.entries(queryParams)) {
    if (action === 'create') {
      url.searchParams.set(name, value);
    } else {
      url.searchParams.delete(name);
    }
  }

  return url.toString();
};

export default createQueryParam;
