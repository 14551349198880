export enum EndPointEnum {
  getProjectsPrefix = 'api/projects'
}

export interface IProjectsRequestModel {
  search?: string;
  first?: number;
  page?: number;
  applicationId?: string;
}
