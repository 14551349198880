import { useCallback, useEffect } from 'react';
import { RudderAnalytics as RudderAnalyticsJS } from '@rudderstack/analytics-js';

import {
  RUDDERSTUCK_ANONYMOUS_ID,
  RUDDERSTUCK_SESSION_ID
} from 'common/constants/common';
import { UserType } from 'providers/authProvider/types';
import { EnvConfig } from 'configs/env';
import { Identify } from './constants';
import isServerSide from 'utils/helpers/isServerSide';
import { PayloadForEvent, RudderStuckTrackEventTypes } from './types';

const useRudderAnalytics = (isInit = false) => {
  const analyticsExist = Boolean(isServerSide && window.rudderanalytics);
  useEffect(() => {
    if (isInit && EnvConfig.ruderStackPlaneUrl && EnvConfig.ruderStackKey) {
      const analytics = new RudderAnalyticsJS();
      analytics.load(EnvConfig.ruderStackKey, EnvConfig.ruderStackPlaneUrl);

      if (isServerSide) {
        window.rudderanalytics = analytics;
        analytics.ready(() => {
          const anonymousId = analytics.getAnonymousId() || '';
          const sessionId = analytics.getSessionId() || '';
          localStorage.setItem(RUDDERSTUCK_ANONYMOUS_ID, anonymousId);
          localStorage.setItem(RUDDERSTUCK_SESSION_ID, String(sessionId));
        });
      }
    }
  }, [isInit]);

  const analyticsIdentify = useCallback(
    (userInfo: UserType) => {
      if (analyticsExist) {
        window.rudderanalytics?.identify({
          type: Identify,
          userId: String(userInfo?.id),
          context: {
            traits: userInfo as {}
          }
        });
      }
    },
    [analyticsExist]
  );

  const analyticsTrack = useCallback(
    <E extends RudderStuckTrackEventTypes>(
      eventName: E,
      payload: PayloadForEvent<E>
    ) => {
      if (analyticsExist) {
        window.rudderanalytics?.track(eventName, payload as {});
      }
    },
    [analyticsExist]
  );

  const analyticsPage = () => {
    if (analyticsExist) {
      window.rudderanalytics?.page();
    }
  };

  return { analyticsTrack, analyticsIdentify, analyticsPage };
};

export default useRudderAnalytics;
