import { EnvConfig } from 'configs/env';
import {
  ajs_aid,
  returnUrlQueryParam,
  targetName
} from 'common/constants/authorization';
import { RUDDERSTUCK_ANONYMOUS_ID } from 'common/constants/common';

const redirectHandlerWithSso = (
  target: 'app-store' | 'dashboard' | 'blank' | 'accounts' | 'onboarding-start',
  returnUrl: string
) => {
  const encodedReturnUrl = encodeURIComponent(returnUrl);
  const anonymousId = localStorage.getItem(RUDDERSTUCK_ANONYMOUS_ID) || '';

  return (window.location.href = `${
    EnvConfig.accountsBackend
  }/callback?${targetName}=${target}&${returnUrlQueryParam}=${encodedReturnUrl}${
    anonymousId ? `&${ajs_aid}=${anonymousId}` : ''
  }`);
};

export default redirectHandlerWithSso;
