import { useContext } from 'react';

import { AuthContext } from 'providers/authProvider/context';
import { IAuthContext } from 'providers/authProvider/types';

const useAuthContext = () => {
  const context = useContext<IAuthContext>(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default useAuthContext;
