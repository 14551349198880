'use client';

import { FC, PropsWithChildren, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ReactQueryClientConfigInstance } from 'configs/reactQuery';

const ReactQueryConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [queryClient] = useState(ReactQueryClientConfigInstance);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        panelPosition="right"
        position="bottom-right"
      />
    </QueryClientProvider>
  );
};

export default ReactQueryConfigProvider;
