import BaseApiHandler from 'infrastructure/apiHandlers/baseApiHandler';
import {
  IAccessTokenApiResponseModel,
  IUserApiResponseModel,
  IUserApiResponseModelByView
} from 'common/globalTypes/interfaces/apiResponseModel';
import { EndPointEnum } from './types';
import { BEARER } from 'common/constants/authorization';

class UserApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  async getAuthUser(
    token?: string
  ): Promise<IUserApiResponseModelByView | null> {
    if (token) {
      const currentUser =
        await this.request.accountsFetcher.get<IUserApiResponseModel>({
          url: EndPointEnum.getAuthUserPrefix,
          cache: 'no-cache',
          headerOptions: {
            Authorization: `${BEARER} ${token}`
          }
        });

      return {
        id: currentUser.id,
        fullName: currentUser.fullName,
        avatarUrl: currentUser.avatarUrl
      };
    }

    return null;
  }

  async getTokenByNonce(nonce: string): Promise<IAccessTokenApiResponseModel> {
    return await this.request.accountsFetcher.get<IAccessTokenApiResponseModel>(
      {
        url: EndPointEnum.getTokenByNoncePrefix,
        queryParam: {
          nonce
        },
        responseModel: 'WithNoDataProperty'
      }
    );
  }

  async logoutUser(): Promise<unknown> {
    return await this.request.accountsFetcher.post({
      url: EndPointEnum.logout,
      hideErrorMessage: true
    });
  }
}

const userApiHandler = new UserApiHandler();

export default userApiHandler;
