import styled from 'styled-components';
import Skeleton from 'antd/lib/skeleton';

import {
  borderRadiusBase,
  colors,
  sizes,
  HEIGHT,
  media
} from 'styles/variables.module';
import Divider from 'components/atoms/Divider';

export const ProjectSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.sm(` 
       display: none;
  `)}

  .ant-select-item-empty {
    padding: ${sizes.sp2xs} !important;
  }
`;

export const DropDownItemsLabel = styled.div`
  padding: ${sizes.sps} 0;
`;

export const EmptyProjectStateWrapper = styled.div`
  background: white;
  padding: ${sizes.sps};
`;

export const ProjectSwitcherMobileWrapper = styled.div`
  ${media.sm(` 
       display: block;
       margin-top: ${HEIGHT.MOBILE.HEADER};
  `)}

  .selected-project {
    display: flex;
    justify-content: space-between;
    padding: ${sizes.sp2xm} ${sizes.spm};
    background: ${colors.gray_1};
    border-bottom: 1px solid ${colors.gray_4};
  }
`;

export const ProjectsSwitcherSkeleton = styled(Skeleton.Input)`
  &&& {
    width: 231px;
    height: ${sizes.sp2xl};
    border-radius: ${borderRadiusBase};
  }
`;

export const ProjectsSwitcherMobileSkeleton = styled(Skeleton.Input)`
  &&& {
    display: block;
    width: 100%;
    height: ${sizes.sp5xl};
  }
`;

export const DividerProjectsSwitcher = styled(Divider)`
  &&& {
    height: 20px;
    background: ${colors.gray_4};
    margin: 0 ${sizes.spm};
    width: 1px;

    &[data-app-single-page='true'] {
      margin: 0 ${sizes.spm} 0 0;
    }
  }
`;
