import styled from 'styled-components';

import { colors, sizes } from 'styles/variables.module';

export const EmptyProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: start;
  background: ${colors.gray_3};
  border-radius: ${sizes.sps};
  padding: ${sizes.spm};
  gap: ${sizes.spXl};
`;

export const EmptyProjectsTitleAndDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.sps};
`;
