'use client';

import { useSelectedLayoutSegments } from 'next/navigation';

import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import Icon from 'components/atoms/Icon';
import { Routes } from 'common/constants/common';
import {
  AppStoreLogoContainer,
  AppStoreLogoDesktopWrapper,
  AppStoreLogoMobileWrapper
} from './styles';

const AppStoreLogo = () => {
  const segments = useSelectedLayoutSegments();
  const { push } = useRouteWithLanguage();

  const goToMainPage = () => {
    const isItCheckoutPage = segments.includes('checkout');

    if (isItCheckoutPage) {
      return (window.location.href = Routes.HOME.pathname);
    }

    return push(Routes.HOME.pathname);
  };

  return (
    <AppStoreLogoContainer onClick={goToMainPage}>
      <AppStoreLogoDesktopWrapper>
        <Icon name="appStoreNewLogo" width={184} height={24} />
      </AppStoreLogoDesktopWrapper>
      <AppStoreLogoMobileWrapper>
        <Icon name="appStoreMobileLogo" width={122} height={20} />
      </AppStoreLogoMobileWrapper>
    </AppStoreLogoContainer>
  );
};

export default AppStoreLogo;
