import styled from 'styled-components';
import Divider from 'antd/lib/divider';
import Menu from 'antd/lib/menu';

import { colors, sizes, media } from 'styles/variables.module';
import UserAvatar from 'components/atoms/UserAvatar';

const { Item } = Menu;

export const UserAvatarContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ArrowIconWrapper = styled.span`
  margin-left: ${sizes.sps};
  ${media.sm(`
    display: flex;
  `)}
`;

export const MenuItemStylesLoggedUser = styled(Item)`
  &&& {
    height: ${sizes.sp3xl};

    &:hover {
      background-color: ${colors.primary_1};
      border-radius: ${sizes.sps};
    }
  }
`;

export const MenuStylesLoggedUser = styled(Menu)`
  && {
    width: 272px;
    padding: ${sizes.spm};
    margin-top: ${sizes.sps};
  }
`;

export const DividerStyle = styled(Divider)`
  margin: ${sizes.sps} 0;
`;

export const UserAvatarStyle = styled(UserAvatar)`
  margin-right: ${sizes.sps};
`;

export const UserNameAndAvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${sizes.sps};
  gap: ${sizes.sp2xm};
`;
