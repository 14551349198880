import { useMutation } from '@tanstack/react-query';

import userApiHandler from 'infrastructure/apiHandlers/user';

const useLogoutMutation = () => {
  const { mutate: logoutUser } = useMutation({
    mutationFn: () => userApiHandler.logoutUser()
  });

  return {
    logoutUser
  };
};

export default useLogoutMutation;
