import styled from 'styled-components';

import { sizes, media, colors } from 'styles/variables.module';

export const PrivaciesContainer = styled.section`
  margin: ${sizes.sp3xl} ${sizes.spm} ${sizes.spm} ${sizes.spm};
  ${media.lg(`
     margin: ${sizes.sp3xl} ${sizes.spm};
  `)}
`;
export const ImagesContainer = styled.div`
  display: flex;
  gap: ${sizes.spXl};
  > img + img {
    ${media.lg(`
     padding-left: 0;
  `)}
  }
  ${media.sm(`
      margin-right: 0;
      display: flex;
      gap: ${sizes.sp2xm};
  `)}
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  gap: ${sizes.spl};
  margin-top: ${sizes.spm};
  & a {
    color: ${colors.gray_9};
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  & a:hover {
    color: ${colors.gray_8};
    font-variant: normal;
  }
  ${media.lg(`
     gap: 0;
  `)}
`;

export const ParagraphContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  ${media.lg(`
     flex-direction: column-reverse;
     justify-content: flex-start;
     align-items: flex-start;
  `)}
  > :first-child {
    padding: ${sizes.spm} 0 0 0;
  }
  > div {
    ${media.lg(`
     display: flex;
     flex-direction: column;
     margin-left: ${sizes.sp2xs};
  `)}
    > span {
      margin-left: ${sizes.spl};
      font-size: ${sizes.spXm};
      cursor: pointer;
      ${media.lg(`
      margin: ${sizes.spm} 0 0 0;
  `)}
    }
  }
`;
